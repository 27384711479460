import React from "react"
import PropTypes from "prop-types"

import RecipeCard from "./recipeCard"

const RecipeList = ({ data, filter }) => (
  <>
    <h4 className="pt-3">{filter}</h4>
    <div className="list-group e_index__list">
      {data
        .filter(node => node.node.recipeType === filter)
        .sort((a, b) => {
          return a.node.name.localeCompare(b.node.name)
        })
        .map(({ node }) => (
          <RecipeCard key={node.id} recipe={node} />
        ))}
    </div>
  </>
)

RecipeList.propTypes = {
  data: PropTypes.array.isRequired,
  filter: PropTypes.string.isRequired,
}

export default RecipeList
