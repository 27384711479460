import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import RecipeList from "../components/index/recipeList"
import SEO from "../components/layout/seo"

const IndexPage = ({ data }) => (
  <Layout>
    <div className="container e_index">
      <SEO title="Home" />
      <h4 className="pt-3">{data.allRecipesJson.totalCount} Recipes</h4>
      <div className="list-group e_index__list">
        <RecipeList data={data.allRecipesJson.edges} filter="Dinner" />
        <RecipeList data={data.allRecipesJson.edges} filter="Side" />
        <RecipeList data={data.allRecipesJson.edges} filter="Breakfast" />
        <RecipeList data={data.allRecipesJson.edges} filter="Sweet" />
      </div>
    </div>
  </Layout>
)

export const query = graphql`
  query {
    allRecipesJson {
      totalCount
      edges {
        node {
          cooked
          cookingTime
          fields {
            slug
          }
          id
          name
          recipeImage
          recipeType
          serves
        }
      }
    }
  }
`

export default IndexPage
