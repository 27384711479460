import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import Image from "../shared/image"

const RecipeCard = props => (
  <Link
    className="e_card card list-group-item list-group-item-action p-0 mb-3 border-top"
    to={props.recipe.fields.slug}
  >
    <div className="row">
      <div className="col-5 col-md-3 position-relative overflow-hidden">
        {props.recipe.cooked && (
          <img
            className="e_card__cooked position-absolute"
            alt=""
            src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%23fff' d='M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z'/%3E%3C/svg%3E"
          />
        )}
        <Image src={props.recipe.recipeImage} />
      </div>
      <div className="col-7 col-md-9 d-flex align-items-center ml-n3 ml-md-0">
        <div className="w-100 pr-md-4">
          <h3 className="h5 mb-2 text-capitalise text-dark">
            {props.recipe.name}
          </h3>
          <div className="text-muted d-flex justify-content-between">
            <small className="text-decoration-none">
              {props.recipe.recipeType}
            </small>
            <small className="ml-auto d-inline-block">
              {props.recipe.serves}
              {" serves / "}
              {props.recipe.cookingTime}
              {" mins"}
            </small>
          </div>
        </div>
      </div>
    </div>
  </Link>
)

RecipeCard.propTypes = {
  recipe: PropTypes.object.isRequired,
}

export default RecipeCard
